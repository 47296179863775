// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-form-oculto-js": () => import("./../../../src/pages/formOculto.js" /* webpackChunkName: "component---src-pages-form-oculto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publicaciones-articulos-[id]-js": () => import("./../../../src/pages/publicaciones/articulos/[id].js" /* webpackChunkName: "component---src-pages-publicaciones-articulos-[id]-js" */),
  "component---src-pages-publicaciones-articulos-index-js": () => import("./../../../src/pages/publicaciones/articulos/index.js" /* webpackChunkName: "component---src-pages-publicaciones-articulos-index-js" */),
  "component---src-pages-publicaciones-index-js": () => import("./../../../src/pages/publicaciones/index.js" /* webpackChunkName: "component---src-pages-publicaciones-index-js" */),
  "component---src-pages-publicaciones-redes-index-js": () => import("./../../../src/pages/publicaciones/redes/index.js" /* webpackChunkName: "component---src-pages-publicaciones-redes-index-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */)
}

